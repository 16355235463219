<template>
  <div class="container mt-4">

    <RevisionRequest
      v-if="this.showRevisionRequests"
      :entityType="this.revisionBillingModelEntity"
      :entityId="this.config.id"
      :mapToRevisionRequest="customMapToRevisionRequest"
      :liveData="this.liveData"
      :currentData="this.currentData"
      :isFromValid="true"
      @selected-revision-request-changed="onSelectedRevisionRequestChanged"
      @start-saving="onStartSaving"
      @finish-saving="onFinishSaving"
      @new-revision-request-published="onRevisionRequestPublished"/>

    <b-spinner v-if="loading"></b-spinner>
    <div v-else class="row">
      <b-col>
        <b-row>
          <b-col class="d-flex align-items-center">
            <BackButton></BackButton>
            <h5 class="ml-2 mb-0">
              <b-link :to="`/onboarding/clients/${client.id}/billing`">{{ client.display_name }}</b-link>
              - Finance Configuration ID {{ config.id }}
            </h5>
          </b-col>
        </b-row>

        <b-card class="border-0 mt-3" :header="translations.billing_model_rules_section">
          <b-row class="mb-3">
            <b-col cols="4">
              <b>{{ translations.fields.billing_model }}</b>
              <p>{{ config.billing_model.name }}</p>
            </b-col>
            <b-col cols="4">
              <b-row>
                <b-col cols="4" class="pr-1">
                  <b>{{ translations.fields.status }}</b>
                  <p class="text-capitalize">
                    <b-badge :variant="formatStatusLabel(config.status)">{{ config.status }}</b-badge>
                  </p>
                </b-col>
                <b-col cols="2" class="pl-0 mt-4" v-if="config.status === BILLING_CONFIG_STATUS_DRAFT && !this.showRevisionRequests">
                  <b-button v-b-tooltip.hover
                        @click="updateStatus(config.id)"
                        size="sm"
                        :title="translations.set_new_billing_status_btn"
                        variant="primary"
                        class="float-right">
                        {{ translations.set_new_billing_status_btn}}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4">
              <b>{{ translations.fields.units }}</b>
              <p>{{ formatBillingUnits(config.billing_units) }}</p>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col cols="4">
              <b>{{ translations.history.predict }}</b>
              <p>{{ formatPredict(config.with_predict) }}</p>
            </b-col>
            <b-col cols="4">
              <b>{{ translations.history.cbt }}</b>
              <p><toggle-button
                :value="config.with_cbt"
                @change="onUpdateBillingConfigToggle('with_cbt', ...arguments)"
                sync/></p>
            </b-col>
            <b-col cols="4">
              <b>{{ translations.history.ect }}</b>
              <p><toggle-button
                :value="config.with_ect"
                @change="onUpdateBillingConfigToggle('with_ect', ...arguments)"
                sync/></p>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col cols="4">
              <b>{{ translations.fields.service_type }}</b>
              <p>{{ formatServiceType(config.service_type) }}</p>
            </b-col>
            <b-col cols="4">
              <b>{{ translations.fields.start_date }}</b>
              <p>{{ formatDate(config.start_date) }}</p>
            </b-col>
            <b-col v-if="config.status === 'active'" cols="4">
              <b-row>
                <b-col cols="4" class="pr-1">
                  <b>{{ translations.fields.end_date }}</b>
                    <VueCtkDateTimePicker
                      format="YYYY-MM-DD"
                      id="endDateSelector"
                      :min-date="minDateAvailable"
                      label="End date"
                      formatted="ll"
                      :no-button-now="true"
                      :only-date="true"
                      :noClearButton="true"
                      v-model="config.end_date"/>
                </b-col>
                <b-col cols="4" class="pl-0 mt-4">
                  <b-button v-if="!this.showRevisionRequests"
                      variant="primary"
                      @click="onClickSaveEndDateOnBillingConfig">
                      <feather type="save"/>&nbsp;{{ translations.fields.end_date_save_btn }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-else cols="4">
              <b>{{ translations.fields.end_date }}</b>
              <p>{{ formatDate(config.end_date) }}</p>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col cols="4">
              <b>{{ translations.fields.aso_groups }}</b>
              <p>{{ formatASOGroups(config.aso_groups) }}</p>
            </b-col>
            <b-col cols="4">
              <b>{{ translations.fields.created_at }}</b>
              <p>{{ formatDatetime(config.created_at) }}</p>
            </b-col>
            <b-col cols="4">
              <b>{{ translations.fields.created_by }}</b>
              <p>{{ config.created_by }}</p>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b>{{ translations.fields.rules }}</b>
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col">{{ translations.rules.rule_id }}</th>
                  <th scope="col">{{ translations.rules.rule_description }}</th>
                  <th scope="col">{{ translations.rules.notes }}</th>
                </tr>
                </thead>
                <tbody v-if="config.rules.length">
                <tr v-for="(ruleItem) in config.rules" :key="ruleItem.id">
                  <td>{{ ruleItem.id }}</td>
                  <td>{{ ruleItem.description }}</td>
                  <td>{{ ruleNotes(ruleItem) }}</td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="3">{{ translations.rules.no_rules }}</td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="border-0 mt-3" :header="translations.fees_conditions_section">
          <b-row>
            <b-col cols="3">
              <b>{{ translations.fields.enrollment_fee }}</b>
              <p>{{ zeroAsDash(config.enrollment_fee) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.three_sessions_fee }}</b>
              <p>{{ zeroAsDash(config.three_sessions_fee) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.nine_sessions_fee }}</b>
              <p>{{ zeroAsDash(config.nine_sessions_fee) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.pain_score_reduction_fee }}</b>
              <p>{{ zeroAsDash(config.pain_score_reduction_fee) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.full_fee }}</b>
              <p>{{ zeroAsDash(config.full_fee) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.session_fee }}</b>
              <p>{{ zeroAsDash(config.session_fee) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.session_cap }}</b>
              <p>{{ zeroAsDash(config.session_cap) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.last_x_days }}</b>
              <p>{{ zeroAsDash(config.last_x_days) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.pepm_fee }}</b>
              <p>{{ zeroAsDash(config.pepm_fee) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.pepm_fee_calculation }}</b>
              <p>{{ formatPEPMFeeCalculation(config.pepm_fee_calculation) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.pepm_eligible_population }}</b>
              <p>{{ formatPEPMEligiblePopulation(config.pepm_eligible_population) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.pepm_employee_count }}</b>
              <p>{{ zeroAsDash(config.pepm_employee_count) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.not_chargeable_health_plans }}</b>
              <feather id="not_chargeable_health_plans_help_icon" class="text-info ml-1" type="info"></feather>
              <b-tooltip target="not_chargeable_health_plans_help_icon"
                         placement="bottom">{{ translations.fields.not_chargeable_health_plans_help }}</b-tooltip>
              <div v-if="config.not_chargeable_health_plans.length > 0 || canEditNotChargeableHealthPlans">
                <multiselect
                  v-model="config.not_chargeable_health_plans"
                  :options="[]"
                  :disabled="doingRequest || !canEditNotChargeableHealthPlans"
                  :multiple="true"
                  :taggable="true"
                  :showNoOptions="false"
                  :showNoResults="false"
                  :placeholder="translations.fields.not_chargeable_health_plans_placeholder"
                  :tagPlaceholder="translations.fields.not_chargeable_health_plans_tag_placeholder"
                  @tag="addNotChargeableHealthPlan"
                  @remove="removeNotChargeableHealthPlansOption"/>
                <b-button v-if="!this.showRevisionRequests"
                  class="float-right mt-1"
                  variant="primary"
                  :disabled="doingRequest || !changedNotChargeableHealthPlans"
                  @click="onClickSaveChangesToNotChargeableHealthPlans">
                  <feather type="save"/>&nbsp;{{ translations.history.not_chargeable_health_plans_save_btn }}
                </b-button>
              </div>
              <p v-else>-</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.currency }}</b>
              <p>{{ config.currency || '-' }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.move_discount_label }}</b>
              <p>{{ config.move_discount || '-' }}</p>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="border-0 mt-3" :header="translations.price_escalator_section">
          <b-row>
            <b-col cols="3">
              <b>{{ translations.fields.price_escalator_start_date }}</b>
              <p>{{ formatDate(config.price_escalator.price_escalator_start_date) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.price_escalator_percentage }}</b>
              <p>{{ zeroAsDash(config.price_escalator.price_escalator_percentage) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.price_escalator_interval }}</b>
              <p>{{ formatPriceEscalatorCadence(config.price_escalator.price_escalator_interval) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.fields.price_escalator_original_billing_config }}</b>
              <p>
             <router-link class=""
               :to="{ name: 'ClientBillingSettingsHistory',params: {clientID: config.client_id,
                 configurationId: config.price_escalator.price_escalator_from_billing_config,
               }}"
                  target="_blank">
              {{ zeroAsDash(config.price_escalator.price_escalator_from_billing_config) }}
              </router-link>
              </p>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="border-0 mt-3" :header="translations.revenue_share_section">
          <b-row>
            <b-col>
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th scope="col">{{ translations.revenue_share.partnership }}</th>
                    <th scope="col">{{ translations.revenue_share.sharing.title }}</th>
                    <th scope="col">{{ translations.revenue_share.calculation }}</th>
                    <th scope="col">{{ translations.revenue_share.billing_mode }}</th>
                    <th scope="col">{{ translations.revenue_share.treatment }}</th>
                    <th scope="col">{{ translations.revenue_share.flat_fee_percentage }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!config.revenue_shares.length">
                    <td colspan="6" class="text-center text-muted">
                      {{ translations.revenue_share.empty_information_history }}
                    </td>
                  </tr>
                  <tr v-for="(item, index) in config.revenue_shares" :key="index">
                    <td>{{ item.partnership_name }}</td>
                    <td>{{ sharingLabel(item.sharing) }}</td>
                    <td>{{ formatRevenueShareCalculation(item.calculation) }}</td>
                    <td>{{ formatRevenueShareBillingMode(item.billing_mode) }}</td>
                    <td>{{ formatRevenueShareTreatment(item.treatment) }}</td>
                    <td>{{ zeroAsDash(item.calculation_value) }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="border-0 mt-3" :header="translations.statements_configurations_section">
          <b-row>
            <b-col>
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th scope="col" class="text-center">{{ translations.statements_configurations.billing_type }}</th>
                    <th scope="col" class="text-center">{{ translations.statements_configurations.billing_period }}</th>
                    <th scope="col" class="text-center">{{ translations.statements_configurations.billing_provider }}</th>
                    <th scope="col" class="text-center">{{ translations.statements_configurations.payer }}</th>
                    <th scope="col" class="text-center">{{ translations.statements_configurations.generate_claims_batches }}</th>
                    <th scope="col" class="text-center">{{ translations.statements_configurations.clearing_house }}</th>
                    <th scope="col" class="text-center">{{ translations.statements_configurations.generate_837_file }}</th>
                    <th scope="col" class="text-center">{{ translations.statements_configurations.generate_external_claims_report }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in statementConfigsWithoutOther" :key="index">
                    <td class="text-center">{{ item.billing_type }}</td>
                    <td class="text-center">{{ item.billing_period }}</td>
                    <td class="text-center">{{ item.billing_provider.name }}</td>
                    <td class="text-center">{{ payerLabel(item.payer) }}</td>
                    <td class="text-center">
                      <b-form-checkbox
                        :checked="item.generate_claims_batches"
                        :disabled="doingRequest || generateClaimsBatchesOrExternalClaimsReportDisabled(item)"
                        @change="generateClaimsBatchesChanged(item.id, ...arguments)"/>
                    </td>
                    <td class="text-center"
                      v-if="!isClearingHouseDisabled(item)">
                      <b-form-group
                        class="p-0 m-0"
                        :disabled="true">
                        <b-select
                          v-model="item.clearing_house"
                          :options="formatClearingHouseOptions()"
                          @change="generateClearingHouseChange(item.id, ...arguments)"/>
                      </b-form-group>
                    </td>
                    <td class="text-center"
                      v-if="isClearingHouseDisabled(item)">
                      {{ clearingHouseLabel(item.clearing_house)}}
                    </td>
                    <td class="text-center">
                      <b-form-checkbox
                        :checked="item.generate_837_file"
                        :disabled="doingRequest || generate837FileDisabled(item)"
                        @change="generate837FileChanged(item.id, ...arguments)"/>
                    </td>
                    <td class="text-center">
                      <b-form-checkbox
                        :checked="item.generate_external_claims_report"
                        :disabled="doingRequest || generateClaimsBatchesOrExternalClaimsReportDisabled(item)"
                        @change="generateExternalClaimsReportChanged(item.id, ...arguments)"/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import Multiselect from 'vue-multiselect';

import revisionRequestMixin from '@/mixins/revisionRequests';
import revisionRequestFinanceMixin from '@/mixins/revisionRequests/finance';
import BackButton from '@/components/BackButton.vue';
import translations from '@/translations';
import RevisionRequest from '@/components/RevisionRequests/index.vue';
import {
  revenueShareCalculationOptions,
  revenueShareBillingModeOptions,
  revenueShareTreatmentOptions,
  pepmEligiblePopulationOptions,
  pepmFeeCalculationOptions,
} from '@/scripts/definitions';
import {
  DEFAULT_CLEARING_HOUSE,
  BILLING_CONFIG_STATUS_DRAFT,
  BILLING_CONFIG_STATUS_NEW,
  REVISION_TAG_STATEMENTS_CONFIGURATIONS,
} from '@/constants/finance';
import { ENTITY_TYPES } from '@/constants/revisionRequest';

export default {
  name: 'ClientBillingSettingsHistory',
  components: {
    BackButton,
    Multiselect,
    RevisionRequest,
  },
  mixins: [ revisionRequestMixin, revisionRequestFinanceMixin ],
  props: {
    clientId: String,
    configurationId: String,
  },
  data() {
    return {
      loading: true,
      doingRequest: false,
      config: null,
      configToCompareWithRevision: null,
      client: null,
      translations: translations.finance.billing_configurations,
      claimsBillingTypes: [ 'Claims', 'Claims (external)' ],
      canEditNotChargeableHealthPlans: false,
      currentNotChargeableHealthPlans: [],
      showRevisionRequests: false,
      liveData: null,
      revisionBillingModelEntity: ENTITY_TYPES.financeBillingModel,
      BILLING_CONFIG_STATUS_DRAFT,
    };
  },
  async beforeMount() {
    this.initRevisionRequest('finance');
    const allConfigs = await this.$store.dispatch('Financial/getBillingHistory', this.clientId);
    if (allConfigs != null) {
      const configurationIdNumber = Number(this.configurationId);
      this.config = allConfigs.find(c => c.id === configurationIdNumber);
    }

    if (this.config) {
      this.config.end_date = this.config.end_date ? this.formatDate(this.config.end_date) : null;
      this.currentNotChargeableHealthPlans = cloneDeep(this.config.not_chargeable_health_plans);
      if (this.config.rules) {
        for (let i = 0; i < this.config.rules.length; i++) {
          if (this.config.rules[i].id === 17 || this.config.rules[i].id === 18) {
            this.canEditNotChargeableHealthPlans = true;
            break;
          }
        }
      }

      await this.$store.dispatch('Core/fetchClients');
      await this.$store.dispatch('Eligibility/fetchTenants', { clientId: this.clientId });
      const clientIdNumber = Number(this.clientId);
      this.client = this.$store.getters['Core/getClients'].find(c => c.id === clientIdNumber);

      if (this.isRevisionRequestEnabled()) {
        this.liveData = this.makeRevisionChangeForFinanceBillingConfig(this.config);
        this.showRevisionRequests = true;
      }

      this.loading = false;
    }

    if (!this.client) {
      this.$noty.error('Could not fetch billing configuration!');
    }
  },
  computed: {
    currentData() {
      return this.makeRevisionChangeForFinanceBillingConfig(this.config);
    },
    changedNotChargeableHealthPlans() {
      return !isEqual(this.currentNotChargeableHealthPlans, this.config.not_chargeable_health_plans);
    },
    statementConfigsWithoutOther() {
      return this.config.statements_configurations.filter(i => i.billing_type !== 'Other');
    },
    minDateAvailable() {
      return moment().utc().add(1, 'day').format('YYYY-MM-DD');
    },
  },
  methods: {
    formatDatetime(value) {
      if (!value) {
        return null;
      }
      return moment(value).utc().format('YYYY-MM-DD HH:mm:ss');
    },
    formatDate(value) {
      if (!value) {
        return '-';
      }
      return moment(value).utc().format('YYYY-MM-DD');
    },
    formatPriceEscalatorCadence(value) {
      return value?.replace('_', ' ');
    },
    formatStatusLabel(label) {
      if (label === 'active') {
        return 'success';
      }
      if (label === 'inactive') {
        return 'danger';
      }
      return 'secondary';
    },
    zeroAsDash(value) {
      if (!value || value === '0' || value === 0) {
        return '-';
      }
      return value;
    },
    sharingLabel(value) {
      if (value) {
        return this.translations.revenue_share.sharing.yes;
      }
      return this.translations.revenue_share.sharing.no;
    },
    clearingHouseLabel(value) {
      if (!value) {
        return '-';
      }

      return (this.formatClearingHouseOptions()
        .find(opt => opt.value === value) || {}).text || value;
    },
    formatClearingHouseOptions() {
      return [
        { value: '', text: '' },
        { value: 'availity', text: 'Availity' },
        { value: 'waystar', text: 'Waystar' },
      ];
    },
    formatRevenueShareCalculation(value) {
      if (!value) {
        return '-';
      }
      const opt = revenueShareCalculationOptions.find(o => o.value === value);
      if (!opt) {
        return '-';
      }
      return opt.text;
    },
    formatRevenueShareBillingMode(value) {
      if (!value) {
        return '-';
      }
      const opt = revenueShareBillingModeOptions.find(o => o.value === value);
      if (!opt) {
        return value;
      }
      return opt.text;
    },
    formatRevenueShareCalculationLabel(value) {
      if (!value) {
        return '-';
      }
      const revenueShareCalculationFields = {
        percentage_transactions: translations.finance.billing_configurations.revenue_share.calculation_share_percentage,
        flat_fee_enrollment: translations.finance.billing_configurations.revenue_share.calculation_share_flat_fee,
      };
      if (!revenueShareCalculationFields[value]) {
        return value;
      }
      return revenueShareCalculationFields[value];
    },
    formatRevenueShareTreatment(value) {
      if (!value) {
        return '-';
      }
      const opt = revenueShareTreatmentOptions.find(o => o.value === value);
      if (!opt) {
        return value;
      }
      return opt.text;
    },
    formatPEPMEligiblePopulation(value) {
      if (!value) {
        return '-';
      }
      const opt = pepmEligiblePopulationOptions.find(o => o.value === value);
      if (!opt) {
        return value;
      }
      return opt.text;
    },
    formatPEPMFeeCalculation(value) {
      if (!value) {
        return '-';
      }
      const opt = pepmFeeCalculationOptions.find(o => o.value === value);
      if (!opt) {
        return value;
      }
      return opt.text;
    },
    payerLabel(payer) {
      if (!payer) {
        return '-';
      }
      return `${payer.payer_external_id} - ${payer.payer_name}`;
    },
    ruleNotes(rule) {
      const notes = [];
      if (rule.active !== undefined && !rule.active) {
        notes.push(this.translations.rules.inactive);
      }

      if (rule.replaced_by) {
        notes.push(`${this.translations.rules.replaced_by} ${rule.replaced_by}`);
      }

      return notes.join(' - ');
    },
    formatBillingUnits(units) {
      if (!Array.isArray(units)) {
        return JSON.stringify(units);
      }
      return units.map(u => u.name).join(', ');
    },
    formatServiceType(serviceType) {
      if (!serviceType) {
        return '-';
      }

      const options = {
        pt_provider: 'PT Provider',
        wellness: 'Wellness',
      };
      if (options[serviceType]) {
        return options[serviceType];
      }
      return serviceType;
    },
    formatASOGroups(asoGroups) {
      if (!asoGroups?.length) {
        return '-';
      }

      const tenants = this.$store.getters['Eligibility/getTenants'];
      const formatted = asoGroups.map(asoGroup => {
        const tenant = tenants?.find(i => i.aso_group === asoGroup);
        if (!tenant || (tenant.deleted_at && tenant.deleted_at > 0)) {
          return `(ID: ${asoGroup} deleted)`;
        }

        return `(${tenant.aso_group_name})`;
      });

      return formatted.join(', ');
    },
    removeNotChargeableHealthPlansOption(opts) {
      delete this.config.not_chargeable_health_plans[opts];
    },
    generateClaimsBatchesOrExternalClaimsReportDisabled(item) {
      return !this.claimsBillingTypes.includes(item.billing_type);
    },
    generate837FileDisabled(item) {
      return !item.generate_claims_batches || this.generateClaimsBatchesOrExternalClaimsReportDisabled(item);
    },
    async generateClaimsBatchesChanged(itemId, newStatus) {
      const itemIndex = this.config.statements_configurations.findIndex(i => i.id === itemId);
      if (itemIndex === -1) {
        return;
      }

      const oldValue = Object.assign({}, this.config.statements_configurations[itemIndex]);
      const newItem = Object.assign({}, this.config.statements_configurations[itemIndex], { generate_claims_batches: newStatus });

      if (newStatus && !newItem.clearing_house?.length) {
        newItem.clearing_house = DEFAULT_CLEARING_HOUSE;
      }

      if (newItem.generate_837_file && !newStatus) {
        await this.$bvModal.msgBoxOk(this.translations.statements_configurations.generate_claims_batches_cannot_disable);
        this.config.statements_configurations.splice(itemIndex, 1, oldValue);
        return;
      }

      this.config.statements_configurations.splice(itemIndex, 1, newItem);

      let confirmMsg = this.translations.statements_configurations.generate_claims_batches_confirm_enable;
      if (!newStatus) {
        confirmMsg = this.translations.statements_configurations.generate_claims_batches_confirm_disable;
      }
      const updated = await this.onUpdateStatementConfig(itemId, confirmMsg);
      if (!updated) {
        this.config.statements_configurations.splice(itemIndex, 1, oldValue);
      }
    },
    async generate837FileChanged(itemId, newStatus) {
      const itemIndex = this.config.statements_configurations.findIndex(i => i.id === itemId);
      if (itemIndex === -1) {
        return;
      }

      const oldValue = Object.assign({}, this.config.statements_configurations[itemIndex]);
      const newItem = Object.assign({}, this.config.statements_configurations[itemIndex], { generate_837_file: newStatus });
      this.config.statements_configurations.splice(itemIndex, 1, newItem);

      let confirmMsg = this.translations.statements_configurations.generate_837_file_confirm_enable;
      if (!newStatus) {
        confirmMsg = this.translations.statements_configurations.generate_837_file_confirm_disable;
      }
      const updated = await this.onUpdateStatementConfig(itemId, confirmMsg);
      if (!updated) {
        this.config.statements_configurations.splice(itemIndex, 1, oldValue);
      }
    },
    async generateExternalClaimsReportChanged(itemId, newStatus) {
      const itemIndex = this.config.statements_configurations.findIndex(i => i.id === itemId);
      if (itemIndex === -1) {
        return;
      }

      const oldValue = Object.assign({}, this.config.statements_configurations[itemIndex]);
      const newItem = Object.assign({}, this.config.statements_configurations[itemIndex], { generate_external_claims_report: newStatus });
      this.config.statements_configurations.splice(itemIndex, 1, newItem);

      let confirmMsg = this.translations.statements_configurations.generate_external_claims_report_confirm_enable;
      if (!newStatus) {
        confirmMsg = this.translations.statements_configurations.generate_external_claims_report_confirm_disable;
      }
      const updated = await this.onUpdateStatementConfig(itemId, confirmMsg);
      if (!updated) {
        this.config.statements_configurations.splice(itemIndex, 1, oldValue);
      }
    },
    async generateClearingHouseChange(itemId, newStatus) {
      const itemIndex = this.config.statements_configurations.findIndex(i => i.id === itemId);
      if (itemIndex === -1) {
        return;
      }

      const oldValue = Object.assign({}, this.config.statements_configurations[itemIndex]);
      const newItem = Object.assign({}, this.config.statements_configurations[itemIndex], { clearing_house: newStatus });

      if (!newStatus && newItem.generate_claims_batches) {
        await this.$bvModal.msgBoxOk(this.translations.statements_configurations.clearing_house_mandatory);
        this.config.statements_configurations.splice(itemIndex, 1, oldValue);
        return;
      }

      this.config.statements_configurations.splice(itemIndex, 1, newItem);

      const confirmMsg = this.translations.statements_configurations.clearing_house_confirm_change;
      const updated = await this.onUpdateStatementConfig(itemId, confirmMsg);
      if (!updated) {
        this.config.statements_configurations.splice(itemIndex, 1, oldValue);
      }
    },
    /**
     * Common logic when changing a toggle checkbox: ask for confirmation, do request, show success or error message.
     * The value of the property is **always** updated to a correct state.
     */
    async onUpdateStatementConfig(itemId, confirmMsg) {
      if (this.isRevisionRequestEnabled()) {
        // we will use revision request to save it
        return true;
      }

      const index = this.config.statements_configurations.findIndex(i => i.id === itemId);
      if (index === -1) {
        return false;
      }

      if (!await this.$bvModal.msgBoxConfirm(confirmMsg)) {
        return false;
      }

      try {
        this.doingRequest = true;
        const payload = this.config.statements_configurations[index];

        await this.$store.dispatch('Financial/updateStatementConfiguration', { id: itemId, payload });
        this.$noty.success(this.translations.statements_configurations.updated);
        return true;
      } catch (err) {
        const errMsg = `${this.translations.statements_configurations.update_failed}: ${err?.response?.data?.error?.detail || err}`;
        this.$noty.error(errMsg);
        return false;
      } finally {
        this.doingRequest = false;
      }
    },
    isClearingHouseDisabled(item) {
      return item.billing_type === 'Direct Invoice';
    },
    addNotChargeableHealthPlan(option) {
      if (typeof option !== 'string') {
        return;
      }
      option = option.trim();
      if (option === '') {
        return;
      }

      if (!this.config.not_chargeable_health_plans.includes(option)) {
        this.config.not_chargeable_health_plans.push(option);
      }
    },
    async onClickSaveChangesToNotChargeableHealthPlans() {
      if (!this.changedNotChargeableHealthPlans) {
        return;
      }

      const addChanges = this.config.not_chargeable_health_plans.map(item => {
        if (!this.currentNotChargeableHealthPlans.includes(item)) {
          return `${this.translations.history.not_chargeable_health_plans_confirm_add} "${item}"`;
        }
        return null;
      });

      const removeChanges = this.currentNotChargeableHealthPlans.map(item => {
        if (!this.config.not_chargeable_health_plans.includes(item)) {
          return `${this.translations.history.not_chargeable_health_plans_confirm_remove} "${item}"`;
        }
        return null;
      });

      const changes = removeChanges.concat(addChanges).filter(i => i !== null);

      if (!await this.confirmNotChargeableHealthPlansUpdate(changes)) {
        return;
      }

      try {
        this.doingRequest = true;

        const payload = { not_chargeable_health_plans: this.config.not_chargeable_health_plans };
        await this.$store.dispatch('Financial/editBillingConfiguration', { id: this.config.id, payload });

        this.currentNotChargeableHealthPlans = cloneDeep(this.config.not_chargeable_health_plans);
        this.$noty.success(this.translations.history.not_chargeable_health_plans_updated);
      } catch (err) {
        let errDetail = err;
        if (err?.response?.data?.error?.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.history.not_chargeable_health_plans_update_error}: ${errDetail}`;
        this.$noty.error(errMsg);
      } finally {
        this.doingRequest = false;
      }
    },
    async confirmNotChargeableHealthPlansUpdate(changes) {
      const h = this.$createElement;

      const changedItems = [];
      changes.forEach(change => {
        changedItems.push(h('li', { style: 'list-style: disc;' }, change));
      });

      const messageVNode = h('div', [
        h('ul', { style: 'padding-left: 2rem;' }, [
          changedItems,
        ]),
      ]);

      return this.$bvModal.msgBoxConfirm([ messageVNode ], {
        title: this.translations.history.not_chargeable_health_plans_save_confirmation,
        size: 'lg',
      });
    },
    onStartSaving() {
      this.doingRequest = true;
    },
    onFinishSaving() {
      this.doingRequest = false;
    },
    onSelectedRevisionRequestChanged(parsedRev, options) {
      if (!parsedRev) {
        parsedRev = cloneDeep(this.liveData);
      }

      if (options?.isLiveData) {
        // backup the current config to compare with the revision request
        this.configToCompareWithRevision = cloneDeep(this.config);
      }

      const parsedRevKeys = Object.keys(parsedRev);
      const newConfig = Object.assign({}, this.config);

      for (let i = 0; i < parsedRevKeys.length; i++) {
        const key = parsedRevKeys[i];

        if (key === REVISION_TAG_STATEMENTS_CONFIGURATIONS) {
          parsedRev[key].forEach(item => {
            const isStatementOther = item.billing_type === 'Other';
            const indexToCompare = newConfig[key].findIndex(oldItem => oldItem.id === item.id);
            if (indexToCompare === -1) {
              newConfig[key].push(item);
            } else if (!isStatementOther && !isEqual(item, newConfig[key][indexToCompare])) {
              newConfig[key][indexToCompare] = item;
            }
          });
        } else if (!isEqual(parsedRev[key], newConfig[key])) {
          newConfig[key] = parsedRev[key];
        }
      }

      this.config = newConfig;
    },
    onRevisionRequestPublished() {
      this.liveData = this.makeRevisionChangeForFinanceBillingConfig(this.config);
    },
    customMapToRevisionRequest(changes, options) {
      const changesKeys = Object.keys(changes);
      const configToCompare = options?.is_publishing && this.configToCompareWithRevision ? this.configToCompareWithRevision : this.config;
      const changesMap = {};

      if (options?.is_saving && configToCompare.status === BILLING_CONFIG_STATUS_DRAFT) {
        changesMap.status = BILLING_CONFIG_STATUS_NEW;
        this.currentData.status = BILLING_CONFIG_STATUS_NEW; // force currentData to be updated
      }

      for (let i = 0; i < changesKeys.length; i++) {
        const key = changesKeys[i];

        if (key === REVISION_TAG_STATEMENTS_CONFIGURATIONS) {
          changes[key].forEach(item => {
            const indexLiveData = this.liveData[key].findIndex(oldItem => oldItem.id === item.id);
            if (this.liveData[key]?.[indexLiveData]) {
              this.liveData[key][indexLiveData].tag = REVISION_TAG_STATEMENTS_CONFIGURATIONS;
            }

            const indexToCompare = configToCompare[key].findIndex(oldItem => oldItem.id === item.id);
            if (configToCompare[key]?.[indexToCompare]) {
              configToCompare[key][indexToCompare].tag = REVISION_TAG_STATEMENTS_CONFIGURATIONS;
            }

            const isEqualLiveData = this.liveData[key]?.[indexLiveData] && !options?.is_publishing
              ? isEqual(item, this.liveData[key][indexLiveData])
              : false;
            const isEqualConfig = configToCompare[key]?.[indexToCompare]
              ? isEqual(item, configToCompare[key][indexToCompare])
              : false;

            const isStatementOther = item.billing_type === 'Other';

            if (!isStatementOther && (!isEqualLiveData || !isEqualConfig)) {
              if (!changesMap[key]) {
                changesMap[key] = [];
              }
              changesMap[key].push(item);
            }
          });
        } else {
          const isEqualLiveData = isEqual(changes[key], this.liveData[key]);
          const isEqualConfig = isEqual(changes[key], configToCompare[key]);

          /**
           * This is necessary because when creating the revision request,
           * the changes are compared with the liveData, and when publishing,
           * the changes become the liveData and must be compared with the current config.
           */
          if (!isEqualLiveData || !isEqualConfig) {
            changesMap[key] = changes[key];
          }
        }
      }

      return changesMap;
    },
    async updateStatus(id) {
      const yes = await this.$bvModal.msgBoxConfirm(this.translations.history.update_status_modal_confirmation, {
        okTitle: this.translations.history.update,
      });

      if (!yes) return;

      const result = await this.$store.dispatch('Financial/updateNewStatus', { configuration_id: id });
      if (result) this.config.status = 'new';
    },
    async onClickSaveEndDateOnBillingConfig() {
      if (!this.config.end_date) {
        this.$noty.error(this.translations.fields.end_date_invalid_value);
        return;
      }

      // do not save if is revision request
      if (this.isRevisionRequestEnabled()) {
        return;
      }

      const payload = {
        configuration_id: this.config.id,
        end_date: this.config.end_date,
      };

      const result = await this.$store.dispatch('Financial/disableBillingConfiguration', payload);
      if (result) this.$noty.success(this.translations.fields.end_date_save_success);
    },
    formatPredict(predict) {
      if (!predict) {
        return '-';
      }

      return this.translations.history.yes;
    },
    onUpdateBillingConfigToggle(property, value) {
      this.onUpdateBillingConfigProperty(property, value?.value);
    },
    async onUpdateBillingConfigProperty(property, value) {
      if (this.isRevisionRequestEnabled()) {
        // we will use revision request to save it
        this.config[property] = value;
        return;
      }

      try {
        this.doingRequest = true;

        const payload = {};
        payload[property] = value;
        await this.$store.dispatch('Financial/editBillingConfiguration', { id: this.config.id, payload });
        this.config[property] = value;
        this.$noty.success(this.translations.history.billing_config_updated);
      } catch (err) {
        const errMsg = `${this.translations.history.billing_config_update_error}: ${err?.response?.data?.error?.detail || err}`;
        this.$noty.error(errMsg);
      }

      this.doingRequest = false;
    },
  },
};
</script>
