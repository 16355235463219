import axios from 'axios';
import { parseResponseData } from '@/http/parsers/parse_response';

const revisionV1Url = 'v1/revision-request';

class RevisionService {
  constructor() {
    this.axios = axios;
  }

  async getRevisionRequestByEntity(entityType, entityId) {
    return this.axios.get(`${revisionV1Url}?entityType=${entityType}&entityId=${entityId}`).then(parseResponseData);
  }

  async createRevisionRequest(payload) {
    return this.axios.post(revisionV1Url, payload).then(parseResponseData);
  }

  async updateRevisionRequest(payload) {
    return this.axios.put(`${revisionV1Url}/${payload.id}`, payload).then(parseResponseData);
  }

  async publishRevisionRequest(revisionId, data) {
    const payload = { current_data: JSON.stringify(data) };
    return this.axios.patch(`${revisionV1Url}/${revisionId}/publish`, payload).then(parseResponseData);
  }

  async approveRevisionRequest(revisionId) {
    return this.axios.patch(`${revisionV1Url}/${revisionId}/approve`).then(parseResponseData);
  }

  async rejectRevisionRequest(revisionId) {
    return this.axios.patch(`${revisionV1Url}/${revisionId}/reject`).then(parseResponseData);
  }

  async submitRevisionRequest(revisionId) {
    return this.axios.patch(`${revisionV1Url}/${revisionId}/submit`).then(parseResponseData);
  }

  async draftRevisionRequest(revisionId) {
    return this.axios.patch(`${revisionV1Url}/${revisionId}/draft`).then(parseResponseData);
  }

  async deleteRevisionRequest(revisionId) {
    return this.axios.delete(`${revisionV1Url}/${revisionId}`).then(parseResponseData);
  }

  async addNotesToRevisionRequest(revisionId, notes) {
    return this.axios.patch(`${revisionV1Url}/${revisionId}/notes`, { notes }).then(parseResponseData);
  }
}

export default new RevisionService();
