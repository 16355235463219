<template>
  <b-modal size="xl" button-size="sm" title="Preview Changes" :visible="isVisible"
    :cancel-title="this.submit.cancel.label" :ok-title="this.submit.ok.label" @show="resetModal" @ok="handleAction"
    @cancel="handleAction" @hidden="close">
    <div class="table-container" v-if="this.changes && this.changes.items.length">
      <b-table striped bordered :fields="this.changes.fields" :items="this.changes.items">
        <template #cell(config)="data">
          <div>
            {{ showConfigName(data) }}
          </div>
        </template>
        <template #cell(current_value)="data">
          <div v-if="specialDiffs.enrollment.includes(data.item.config)">
            <img :src="getClientLogoImageById(data.item.current_value)" alt="Current client logo" class="mb-3" style="max-width: 10rem;">
          </div>
          <div v-else-if="specialDiffs.conditionsMapping.includes(data.item.config)">
            <ul class="list-unstyled">
              <li v-for="item in data.item.current_value" :key="item.key">
                {{ item.key }}:
                <b-badge variant="success" v-if="item.enabled">Yes</b-badge>
                <b-badge variant="danger" v-else>No</b-badge>
              </li>
            </ul>
          </div>
          <div v-else-if="specialDiffs.client.includes(data.item.config)">
            <ul class="list-unstyled">
              <li v-for="item in data.item.current_value" :key="item.key">
                {{ item.slug }}:
                <b-badge variant="success" v-if="item.enabled">ENABLED</b-badge>
                <b-badge variant="danger" v-else>DISABLED</b-badge>
                <strong v-if="item.is_primary" > ( Primary ) </strong>
              </li>
            </ul>
          </div>
          <div v-else-if="specialDiffs.financePage.includes(getTagFromConfigs(data.item))">
            <ul class="list-unstyled" v-for="(item, key) in itemsToShowInFinanceDiff(data.item.current_value)" :key="key">
              <li v-for="(subItem, objKey) in item" :key="objKey">
                {{ translations[objKey] || objKey }}:
                <b-badge variant="success" v-if="subItem === true">Yes</b-badge>
                <b-badge variant="danger" v-else-if="subItem === false">No</b-badge>
                <b-badge v-else>{{ subItem }}</b-badge>
              </li>
              <hr v-if="data.item.current_value && key < (data.item.current_value.length - 1)" />
            </ul>
          </div>
          <div v-else-if="getDiffType(data.item.current_value) === 'array'">
            {{ data.item.current_value.join(', ') }}
          </div>
          <div v-else-if="getDiffType(data.item.current_value) === 'object'">
            <ul class="list-unstyled">
              <li v-for="(value, key) in data.item.current_value" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </div>
          <div v-else>
            {{ data.item.current_value }}
          </div>
        </template>
        <template #cell(new_value)="data">
          <div v-if="specialDiffs.enrollment.includes(data.item.config)">
            <img :src="getClientLogoImageById(data.item.new_value)" alt="New client logo" class="mb-3" style="max-width: 10rem;">
          </div>
          <div v-else-if="specialDiffs.conditionsMapping.includes(data.item.config)">
            <ul class="list-unstyled">
              <li v-for="item in data.item.new_value" :key="item.key">
                {{ item.key }}:
                <b-badge variant="success" v-if="item.enabled">Yes</b-badge>
                <b-badge variant="danger" v-else>No</b-badge>
              </li>
            </ul>
          </div>
          <div v-else-if="specialDiffs.client.includes(data.item.config)">
            <ul class="list-unstyled">
              <li v-for="item in data.item.new_value" :key="item.key">
                {{ item.slug }}:
                <b-badge variant="success" v-if="item.enabled">ENABLED</b-badge>
                <b-badge variant="danger" v-else>DISABLED</b-badge>
                <strong v-if="item.is_primary" > ( Primary ) </strong>
              </li>
            </ul>
          </div>
          <div v-else-if="specialDiffs.financePage.includes(getTagFromConfigs(data.item))">
            <ul class="list-unstyled" v-for="(item, key) in itemsToShowInFinanceDiff(data.item.new_value)" :key="key">
              <li v-for="(subItem, objKey) in item" :key="objKey">
                {{ translations[objKey] || objKey }}:
                <b-badge variant="success" v-if="subItem === true">Yes</b-badge>
                <b-badge variant="danger" v-else-if="subItem === false">No</b-badge>
                <b-badge v-else>{{ subItem }}</b-badge>
              </li>
              <hr v-if="data.item.new_value && key < (data.item.new_value.length - 1)" />
            </ul>
          </div>
          <div v-else-if="getDiffType(data.item.new_value) === 'array'">
            {{ data.item.new_value.join(', ') }}
          </div>
          <div v-else-if="getDiffType(data.item.new_value) === 'object'">
            <ul class="list-unstyled">
              <li v-for="(value, key) in data.item.new_value" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </div>
          <div v-else>
            {{ data.item.new_value }}
          </div>
        </template>

      </b-table>
    </div>
    <b-alert v-else show variant="warning" class='mt-12 text-center'>No changes found comparing with current</b-alert>
  </b-modal>
</template>

<script>
import translations from '@/translations';
import { ENTITY_TYPES, REVISION_STATUS } from '@/constants/revisionRequest';
import { REVISION_TAG_STATEMENTS_CONFIGURATIONS } from '@/constants/finance';
import revisionRequestFinanceMixin from '@/mixins/revisionRequests/finance';

import objectDiff from './objectDiff';

export default {
  name: 'preview-changes-modal',
  data() {
    return {
      translations: translations.revision_requests.client,
      changes: {
        fields: [
          { key: 'config', label: 'Config' },
          { key: 'current_value', label: 'Current value' },
          { key: 'new_value', label: 'New value' },
        ],
        items: [],
      },
      submit: {
        ok: {
          label: 'ok',
          action: null,
        },
        cancel: {
          label: 'cancel',
          action: null,
        },
      },
      specialDiffs: {
        enrollment: [
          'logo.current',
        ],
        conditionsMapping: [
          'configuration.dpt.conditionsMapping',
          'configuration.bloom.conditionsMapping',
          'configuration.dpt_go.conditionsMapping',
        ],
        client: [ 'slugs' ],
        financePage: [
          REVISION_TAG_STATEMENTS_CONFIGURATIONS,
        ],
      },
    };
  },
  mixins: [ revisionRequestFinanceMixin ],
  props: {
    currentData: Object,
    isVisible: Boolean,
    skipToApproveAndPublish: Boolean,
    oldData: Object,
    newData: Object,
    revisionRequest: Object,
  },
  methods: {
    getTagFromConfigs(data) {
      return data.current_value?.[0]?.tag !== undefined && data.current_value[0].tag !== '' ? data.current_value[0].tag : data.config;
    },
    itemsToShowInFinanceDiff(items) {
      if (!items || items[0] === undefined) return [];
      const itemsToShow = [
        'billing_type',
        'payer',
        'generate_claims_batches',
        'clearing_house',
        'generate_837_file',
        'generate_external_claims_report',
      ];
      return items.map(item => {
        const isStatementOther = item.billing_type?.toLowerCase() === 'other';
        if (isStatementOther) {
          return null;
        }

        return itemsToShow.reduce((acc, key) => {
          const isPayerEmpty = key === 'payer' && !item[key];
          if (!isStatementOther && !isPayerEmpty) {
            acc[key] = key === 'payer' ? item[key]?.payer_name : item[key];
          }
          return acc;
        }, {});
      });
    },
    showConfigName(data) {
      if (this.specialDiffs.financePage.includes(this.getTagFromConfigs(data.item))) {
        return this.translations[data.item.current_value[0].tag];
      }

      return this.translations[data.item.config] || data.item.config;
    },
    handleAction(bvModalEvent) {
      if (this.submit[bvModalEvent.trigger].action) {
        this.$emit(this.submit[bvModalEvent.trigger].action, this.revisionRequest);
      }
    },
    resetModal() {
      switch (this.revisionRequest.status) {
        case REVISION_STATUS.draft:
          this.submit.ok.label = 'Submit for approval';
          this.submit.ok.action = 'submitForApproval';
          this.submit.cancel.label = 'Cancel';
          this.submit.cancel.action = 'cancel';
          break;
        case REVISION_STATUS.pending:
          this.submit.ok.label = this.skipToApproveAndPublish ? 'Approve & Publish' : 'Approve';
          this.submit.ok.action = 'approve';
          this.submit.cancel.label = 'Reject';
          this.submit.cancel.action = 'reject';
          break;
        case REVISION_STATUS.approved:
          this.submit.ok.label = 'Publish';
          this.submit.ok.action = 'publish';
          this.submit.cancel.label = null;
          this.submit.cancel.action = null;
          break;
        default:
          this.submit.ok.label = 'ok';
          this.submit.ok.action = null;
          this.submit.cancel.label = 'cancel';
          this.submit.cancel.action = null;
          break;
      }

      if (this.revisionRequest.entity_type === ENTITY_TYPES.financeBillingModel) {
        // getFinanceBillingConfigDiff is a method from revisionRequestFinanceMixin
        this.changes.items = this.getAllPropertyPaths(this.getFinanceBillingConfigDiff(this.oldData, this.newData));
      } else {
        this.changes.items = this.getAllPropertyPaths(objectDiff(this.oldData, this.newData));
      }
    },
    close() {
      this.$emit('close');
    },
    getDiffType(data) {
      if ((!data && data !== false) || data.length === 0) {
        return 'empty';
      }
      if (Array.isArray(data)) {
        return 'array';
      }
      if (typeof data === 'object' && data !== null) {
        return 'object';
      }
      return typeof stringValue;
    },
    getAllPropertyPaths(obj, path = '') {
      /* eslint-disable */
      return Object.keys(obj).reduce((acc, key) => {
        const newPath = path ? `${path}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          return Array.isArray(obj[key])
            ? [...acc, { config: newPath, current_value: obj[key][0], new_value: obj[key][1] }]
            : [...acc, ...this.getAllPropertyPaths(obj[key], newPath)];
        }
        return [...acc, { path: newPath, value: obj[key] }];
      }, []);
      /* eslint-enable */
    },
    getClientLogoImageById(clientLogoID) {
      return this.currentData.logo.available_logos.find(({ id }) => id === clientLogoID)?.url;
    },
  },
};
</script>
