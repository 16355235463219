import { cloneDeep, isEqual } from 'lodash';

import { REVISION_TAG_STATEMENTS_CONFIGURATIONS } from '@/constants/finance';

export default {
  methods: {
    makeRevisionChangeForFinanceBillingConfig(billingConfig) {
      const change = cloneDeep(billingConfig);
      const revisionChange = {
        end_date: change.end_date,
        not_chargeable_health_plans: change.not_chargeable_health_plans,
        with_cbt: change.with_cbt,
        with_ect: change.with_ect,
        status: change.status,
        statements_configurations: [],
      };

      for (let i = 0; i < billingConfig.statements_configurations?.length; i++) {
        if (billingConfig.statements_configurations[i].billing_type !== 'Other') {
          revisionChange.statements_configurations[i] = {
            ...billingConfig.statements_configurations[i],
            tag: REVISION_TAG_STATEMENTS_CONFIGURATIONS,
          };
        }
      }

      return revisionChange;
    },
    getFinanceBillingConfigDiff(oldConfig, newConfig) {
      const newConfigKeys = Object.keys(newConfig);

      const diff = {};

      for (let i = 0; i < newConfigKeys.length; i++) {
        const key = newConfigKeys[i];

        if (key === REVISION_TAG_STATEMENTS_CONFIGURATIONS) {
          const newItems = [];
          const oldItems = [];

          newConfig[key].forEach(item => {
            const isStatementOther = item.billing_type === 'Other';
            const oldIndex = oldConfig[key].findIndex(oldItem => oldItem.id === item.id);
            if (!isStatementOther && !isEqual(item, oldConfig[key][oldIndex])) {
              newItems.push(item);
              oldItems.push(oldConfig[key][oldIndex]);
            }
          });

          if (newItems.length) {
            diff[key] = [ oldItems, newItems ];
          }
        } else if (!isEqual(oldConfig[key], newConfig[key])) {
          diff[key] = [ oldConfig[key], newConfig[key] ];
        }
      }

      return diff;
    },
  },
};
