<template>
  <b-modal
    size="lg"
    button-size="sm"
    title="Revisions Requests - Notes"
    :visible="isVisible"
    :ok-title="'Save'"
    :ok-disabled="!isValid"
    @ok="handleOk"
    @hidden="close">
    <b-card class="d-flex justify-content-end" cols="12">
      <b-row >
        <b-col cols="12" class="text-right">
          <feather v-if="isEditable" class="ml-3" role="button" type="edit-3" v-b-tooltip.hover title="Edit notes" @click="openEdit"/>
        </b-col>
        <b-col class="mt-3">
          <b-form-textarea :disabled="isDisabled" placeholder="Enter a note" v-model="revisionRequest.notes" rows="3" max-rows="6"/>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>
<script>
import Vue from 'vue';

import translations from '@/translations';
import service from '@/services/revision-service';

const EDITABLE_STATUS = [ 'draft' ];

export default {
  name: 'modal-notes',
  data() {
    return {
      translations: translations.revision_requests,
      isEditing: false,
    };
  },
  props: {
    isVisible: Boolean,
    revisionRequest: Object,
  },
  computed: {
    isValid() {
      return this.revisionRequest?.notes?.length <= 1000 && this.revisionRequest?.notes?.length > 0;
    },
    isEditable() {
      return EDITABLE_STATUS.includes(this.revisionRequest?.status) && !this.isEditing;
    },
    isDisabled() {
      return !this.isEditing;
    },
  },
  methods: {
    close() {
      this.closeEdit();
      this.$emit('close');
    },
    handleOk() {
      if (this.isEditing && this.isValid) {
        this.saveNotes();
      }
      this.isEditing = false;
      this.$emit('ok');
    },
    openEdit() {
      this.isEditing = true;
    },
    closeEdit() {
      this.isEditing = false;
    },
    async saveNotes() {
      if (!this.isValid) {
        Vue.prototype.$noty.error('Notes must be less than 1000 characters');
        return;
      }

      try {
        await service.addNotesToRevisionRequest(this.revisionRequest.id, this.revisionRequest.notes);
        Vue.prototype.$noty.success(`Notes revision request saved successfully id: ${this.revisionRequest.id}`);
      } catch (error) {
        Vue.prototype.$noty.error(error.response?.data?.message || translations.errors.addNotes, error);
      } finally {
        this.isEditing = false;
      }
    },
  },
};
</script>
