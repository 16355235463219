<template>
  <b-modal
    size="xl"
    button-size="sm"
    title="Revisions Requests"
    :visible="isVisible"
    :ok-title="'Close'"
    @ok="handleOk"
    @hidden="close">
    <b-col v-if="revisionRequests.length" cols="12">
      <b-table :fields="revisions.fields" :busy="fetchingResults" :items="revisionRequests"
      :sort-by.sync="revisions.sortBy" :sort-desc.sync="revisions.sortDesc"
              outlined sticky-header="500px" striped>
        <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
        </template>
        <template #cell(created_at)="data">{{ formatDate(data.item.created_at) }}</template>
        <template #cell(creator)="data">{{ formateRequestedBy(data.item) }}</template>
        <template #cell(updated_at)="data">{{ formatDate(data.item.updated_at) }}</template>
        <template #cell(updated_by)="data">{{ formateUpdatedBy(data.item) }}</template>
        <template #cell(decided_by)="data">{{ formateDecidedBy(data.item) }}</template>
        <template #cell(actions)="data">
            <feather class="ml-3" role="button" type="message-circle"
                     v-b-tooltip.hover title="Click to view notes" @click="showNotes(data.item)"/>
            <feather type="eye" role="button" title="Show" class="ml-1" v-b-tooltip.hover
                    @click="showDiff(data.item)"/>
            <feather type="trash" role="button" v-if="showDelete(data.item)" v-b-tooltip.hover
                    title="delete" class="ml-1"
                    @click="deleteRev(data.item)"/>
            <feather type="corner-up-left" role="button" v-b-tooltip.hover title="back to draft"
                    v-if="showBackToDraft(data.item)" class="ml-1" @click="backToDraft(data.item)"/>
        </template>
      </b-table>
    </b-col>
    <b-alert v-else show variant="warning" class='mt-12 text-center'>
      No Revision Requests for this {{ this.translations.entity_types[entityType] || entityType }}
    </b-alert>
    <PreviewChangesModal
        :currentData="this.currentData"
        :newData="this.newData"
        :oldData="this.oldData"
        :isVisible="this.showDiffModal"
        :skipToApproveAndPublish="this.skipToApproveAndPublish"
        :revisionRequest="this.selectedRevisionRequest"
        @close="toggleDiffModal"
        @submitForApproval="submitForApproval"
        @reject="reject"
        @approve="approve"
        @publish="publish">
    </PreviewChangesModal>
    <NotesModal v-if="this.selectedRevisionRequest"
        :isVisible="this.showNotesModal"
        :revisionRequest="this.selectedRevisionRequest"
        @close="toggleNotesModal">
      </NotesModal>
  </b-modal>
</template>

<script>
import * as R from 'ramda';
import Vue from 'vue';
import moment from 'moment';
import translations from '@/translations';
import { REVISION_STATUS } from '@/constants/revisionRequest';
import service from '@/services/revision-service';

import NotesModal from './notesModal.vue';
import PreviewChangesModal from './previewChangesModal.vue';

export default {
  name: 'modal-revision-requests',
  components: {
    PreviewChangesModal,
    NotesModal,
  },
  data() {
    return {
      translations: translations.revision_requests,
      type: '',
      selectedRevisionRequest: {},
      newData: {},
      oldData: {},
      showDiffModal: false,
      showNotesModal: false,
      revisions: {
        sortBy: 'created_at',
        sortDesc: true,
        fields: [
          { key: 'id', label: '#' },
          { key: 'created_at', label: 'Created At' },
          { key: 'creator', label: 'Created By' },
          { key: 'updated_at', label: 'Updated At' },
          { key: 'updated_by', label: 'Updated By' },
          { key: 'decided_by', label: 'Decided By' },
          { key: 'status', label: 'Status' },
          {
            key: 'actions', label: 'Actions', thClass: 'text-right', tdClass: 'text-right',
          },
        ],
      },
    };
  },
  props: {
    currentData: Object,
    fetchingResults: Boolean,
    isVisible: Boolean,
    skipToApproveAndPublish: Boolean,
    entityType: String,
    entityId: Number,
    liveData: Object,
    mapForDiff: Function,
    hiddenFields: Array,
    revisionRequests: Array,
  },
  methods: {
    showDelete(rev) {
      return [ 'draft' ].includes(rev.status);
    },
    toggleNotesModal() {
      this.showNotesModal = !this.showNotesModal;
    },
    showNotes(rev) {
      this.selectedRevisionRequest = rev;
      this.toggleNotesModal();
    },
    showBackToDraft(rev) {
      return [ 'pending_approval', 'approved' ].includes(rev.status);
    },
    formateRequestedBy(item) {
      if (item.requested_by) {
        return item.requested_by.fullname;
      }

      if (item.requested_by_acl_uuid) {
        return item.requested_by_acl_uuid.fullname;
      }

      return '';
    },
    formateUpdatedBy(item) {
      if (item.updated_by) {
        return item.updated_by.fullname;
      }

      if (item.updated_by_acl_uuid) {
        return item.updated_by_acl_uuid.fullname;
      }

      return '';
    },
    formateDecidedBy(item) {
      if (item.decided_by) {
        return item.decided_by.fullname;
      }

      if (item.decided_by_acl_uuid) {
        return item.decided_by_acl_uuid.fullname;
      }

      return '';
    },
    formatDate(value) {
      if (!value) {
        return '-';
      }
      return moment(value).utc().format('YYYY-MM-DD HH:mm:ss');
    },
    handleOk() {
      this.$emit('ok');
    },
    close() {
      this.selectedRevisionRequest = null;
      this.$emit('close');
    },
    formatDatetime(value) {
      if (!value) {
        return '';
      }
      return moment(value).utc().format('YYYY-MM-DD HH:mm:ss');
    },
    showDiff(rev) {
      this.selectedRevisionRequest = rev;
      const changes = this.selectedRevisionRequest.requested_changes;
      this.newData = R.clone(this.mapForDiff ? this.mapForDiff(changes) : changes);
      this.oldData = R.clone(this.liveData);
      if (this.selectedRevisionRequest.status === REVISION_STATUS.published && this.selectedRevisionRequest.before_changes) {
        const oldData = this.selectedRevisionRequest.before_changes;
        this.oldData = R.clone(this.mapForDiff ? this.mapForDiff(oldData) : oldData);
      }
      this.deleteHiddenFields();
      this.toggleDiffModal();
    },
    deleteHiddenFields() {
      if (this.hiddenFields && this.hiddenFields.length > 0) {
        this.hiddenFields.forEach(field => {
          this.deleteField(this.newData, field);
          this.deleteField(this.oldData, field);
        });
      }
    },
    deleteField(obj, propertyPath) {
      const properties = propertyPath.split('.');
      const lastProperty = properties.pop();

      properties.forEach(property => {
        if (!obj[property] || typeof obj[property] !== 'object') {
          return; // Property path doesn't exist or isn't an object
        }
        obj = obj[property];
      });

      if (obj[lastProperty] !== undefined) {
        delete obj[lastProperty];
      }
    },
    toggleDiffModal() {
      this.showDiffModal = !this.showDiffModal;
    },
    async submitForApproval() {
      try {
        await service.submitRevisionRequest(this.selectedRevisionRequest.id);
        Vue.prototype.$noty.success(this.translations.success.submit);
        this.$emit('refresh');
      } catch (e) {
        Vue.prototype.$noty.error(e.response?.data?.message || this.translations.errors.submit, e);
      }
    },
    async reject() {
      try {
        await service.rejectRevisionRequest(this.selectedRevisionRequest.id);
        Vue.prototype.$noty.success(this.translations.success.reject);
        this.$emit('refresh');
      } catch (e) {
        Vue.prototype.$noty.error(e.response?.data?.message || this.translations.errors.reject, e);
      }
    },
    async approve() {
      try {
        await service.approveRevisionRequest(this.selectedRevisionRequest.id);

        if (this.skipToApproveAndPublish) {
          this.publish();
          return;
        }

        Vue.prototype.$noty.success(this.translations.success.approve);
        this.$emit('refresh');
      } catch (e) {
        Vue.prototype.$noty.error(e.response?.data?.message || this.translations.errors.approve, e);
      }
    },
    publish() {
      this.$emit('publish-revision', this.selectedRevisionRequest);
    },
    deleteRev(rev) {
      this.selectedRevisionRequest = null;
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this revision ?').then(async value => {
        if (value) {
          try {
            await service.deleteRevisionRequest(rev.id);
            Vue.prototype.$noty.success(this.translations.success.delete);
            this.$emit('refresh');
          } catch (e) {
            Vue.prototype.$noty.error(e.response?.data?.message || this.translations.errors.delete, e);
          }
        }
      }).catch(err => {
        console.error(err);
      });
    },
    backToDraft(rev) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to put this revision back to draft ?').then(async value => {
        if (value) {
          try {
            await service.draftRevisionRequest(rev.id);
            Vue.prototype.$noty.success(this.translations.success.draft);
            this.$emit('refresh');
          } catch (e) {
            Vue.prototype.$noty.error(e.response?.data?.message || this.translations.errors.draft, e);
          }
        }
      }).catch(err => {
        console.error(err);
      });
    },
  },
};
</script>
