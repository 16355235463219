import service from '@/services/revision-service';

const revisionRequestServices = {
  finance: 'isRevisionRequestsEnabledForFinance',
};

export default {
  data() {
    return {
      isModuleEnabled: false,
    };
  },
  methods: {
    initRevisionRequest(module, isDefault = false) {
      if (isDefault) {
        this.isModuleEnabled = true;
        return;
      }

      const moduleRequestName = revisionRequestServices[module];
      if (moduleRequestName) {
        this.isModuleEnabled = this.$store.getters[`Core/${moduleRequestName}`];
      }
    },
    isRevisionRequestEnabled() {
      return this.isModuleEnabled;
    },
    _createRevision(revision) {
      return service.createRevisionRequest(revision);
    },
    _updateRevision(revision) {
      return service.updateRevisionRequest(revision);
    },
    saveRevision(revision) {
      if (!this.isRevisionRequestEnabled()) {
        return null;
      }

      if (revision.id) {
        return this._updateRevision(revision);
      }
      return this._createRevision(revision);
    },
    makeRevisionData(entityId, entityType, data, currentRevision) {
      return {
        id: currentRevision?.id || 0,
        entity_id: entityId,
        entity_type: entityType,
        requested_changes: JSON.stringify(data),
        updated_at: currentRevision?.updated_at || null,
      };
    },
  },
};
