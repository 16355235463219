export const ENTITY_TYPES = {
  client: 'client',
  financeBillingModel: 'finance-billing-model',
};

export const ENTITY_TYPES_SKIP_TO_APPROVE_AND_PUBLISH = [
  ENTITY_TYPES.client,
  ENTITY_TYPES.financeBillingModel,
];

export const REVISION_STATUS = {
  draft: 'draft',
  pending: 'pending_approval',
  approved: 'approved',
  rejected: 'rejected',
  published: 'published',
};
