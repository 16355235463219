<template>
  <b-row class="mt-3">
    <b-col cols="12">
      <b-alert :variant="alertColor" show>
        <b-row>
          <b-col cols="10">
            <span v-if="viewLiveData || !this.selectedRevisionRequest">You are viewing live data.
              <span v-if="this.pendingRevisionsRequests.length <= 0">There are no pending revision requests</span>
            </span>
            <span v-else>
              You are viewing an revision request in <b>{{ this.selectedRevisionRequest.status || REVISION_STATUS.draft }} status</b>
            </span>
          </b-col>
          <b-col cols="2" class="text-right">

            <feather class="ml-3" role="button" type="cloud" v-if="viewLiveData && this.selectedRevisionRequest" @click="viewLiveDataToggle"
                     v-b-tooltip.hover title="You are viewing live data. Click to switch to draft."/>
            <feather class="ml-3" role="button" type="cloud-off" v-if="!viewLiveData && this.selectedRevisionRequest" @click="viewLiveDataToggle"
                     v-b-tooltip.hover title="You are viewing a draft. Click to switch to live data."/>

            <feather class="ml-3" role="button" type="message-circle" v-if="this.selectedRevisionRequest"
                     v-b-tooltip.hover title="Click to view notes" @click="showNotesToggle"/>

            <feather class="ml-3" role="button" type="clock"
              v-b-tooltip.hover title="View all revisions" @click="showListToggle"/>

            <feather class="ml-3" role="button" v-if="isFromValid" v-b-tooltip.hover title="Save current draft"
                     type="save" @click="saveRevisionRequest"/>
            <feather class="ml-3" v-else v-b-tooltip.hover title="Form is not valid" type="alert-triangle"/>

          </b-col>
        </b-row>
      </b-alert>

      <NotesModal v-if="this.selectedRevisionRequest"
        :isVisible="this.showNotes"
        :revisionRequest="this.selectedRevisionRequest"
        @close="showNotesToggle">
      </NotesModal>

      <ListModal
         :currentData="this.clonedCurrentData"
         :fetchingResults="this.fetchingResults"
         :entityType="this.entityType"
         :entityId="this.entityId"
         :liveData="this.liveData"
         :isVisible="this.showList"
         :skipToApproveAndPublish="this.skipToApproveAndPublish"
         :mapForDiff="this.mapToEntity"
         :hiddenFields="this.hiddenFields"
         :revisionRequests="this.revisionRequests"
         @close="showListToggle"
         @refresh="resetModal"
         @publish-revision="publishRevision">
      </ListModal>
    </b-col>
  </b-row>
</template>

<script>
import * as R from 'ramda';
import Vue from 'vue';

import revisionRequestMixin from '@/mixins/revisionRequests';
import translations from '@/translations';
import {
  REVISION_STATUS,
  ENTITY_TYPES_SKIP_TO_APPROVE_AND_PUBLISH,
} from '@/constants/revisionRequest';
import service from '@/services/revision-service';

import ListModal from './listModal.vue';
import NotesModal from './notesModal.vue';

export default {
  name: 'revision-requests',
  components: {
    ListModal,
    NotesModal,
  },
  mixins: [ revisionRequestMixin ],
  data() {
    return {
      translations: translations.revision_requests,
      skipToApproveAndPublish: false,
      type: '',
      fetchingResults: false,
      pendingRevisionsRequests: [],
      revisionRequests: [],
      selectedRevisionRequest: null,
      showList: false,
      showNotes: false,
      viewLiveData: false,
      currentChanges: null,
    };
  },
  props: {
    // the type of the entity that we want to save
    entityType: String,
    // the id of the entity that we want to save
    entityId: Number,
    // the live state of the entity
    liveData: Object,
    // data that we want to save
    currentData: Object,
    // map the data to the format that we want to save, if not provided, the data will be saved as it is
    // this is useful if you are saving revisionRequest in a different format than the entity
    // This is used for save and publish, and the options is_saving and is_publishing are injected to differentiate if necessary.
    // function (entityData, options) { return revisionRequestData; }
    mapToRevisionRequest: Function,
    // map data from the revision request to the entity format, if not provided, the data will be used as it is
    // this is useful if you are saving revisionRequest in a different format than the entity and needs to revert it
    // function (revisionRequestData) { return entityData; }
    mapToEntity: Function,
    // if the form is valid
    isFromValid: Boolean,
    // if you want to hide some fields from the revision request diff, provide the full path ex: client.configuration.dpt.conditions
    hiddenFields: Array,
  },
  beforeMount() {
    this.initRevisionRequest('revision-requests', true); // init mixin using default config
    this.resetModal();
  },
  watch: {
    entityType: {
      immediate: true,
      handler() {
        this.skipToApproveAndPublish = ENTITY_TYPES_SKIP_TO_APPROVE_AND_PUBLISH.includes(this.entityType);
      },
    },
  },
  methods: {
    async resetModal() {
      this.fetchingResults = true;

      try {
        const { items } = await service.getRevisionRequestByEntity(this.entityType, this.entityId);
        const pendingStatus = [ REVISION_STATUS.pending, REVISION_STATUS.draft, REVISION_STATUS.approved ];
        this.revisionRequests = items || [];
        this.pendingRevisionsRequests = this.revisionRequests.filter(rev => pendingStatus.includes(rev.status));

        // considering that we will only have one pending revision request at a time
        const selectedRevisionRequest = this.pendingRevisionsRequests[0] || null;
        this.selectedRevisionRequestChanged(selectedRevisionRequest);
      } catch (e) {
        Vue.prototype.$noty.error(e.response?.data?.message || this.translations.errors.fetch, e);
      } finally {
        this.fetchingResults = false;
      }
    },
    showListToggle() {
      if (this.viewLiveData) {
        // if we are in live data mode, we need to switch to draft mode
        this.viewLiveDataToggle();
      }
      this.showList = !this.showList;
    },
    showNotesToggle() {
      this.showNotes = !this.showNotes;
    },
    isDataEquals(changes) {
      return R.equals(this.liveData, R.pick(R.keys(this.liveData), changes));
    },
    viewLiveDataToggle() {
      this.viewLiveData = !this.viewLiveData;
      if (this.viewLiveData) {
        this.currentChanges = this.currentData;
        this.$emit('selected-revision-request-changed', this.liveData, { isLiveData: true });
      } else {
        this.$emit('selected-revision-request-changed', this.currentChanges);
      }
    },
    async saveRevisionRequest() {
      const changes = this.currentData;
      const opts = { is_saving: true };
      const newData = this.mapToRevisionRequest ? this.mapToRevisionRequest(changes, opts) : changes;
      // validate save conditions
      const validator = this.validateSave();
      if (!validator.valid) {
        this.$bvModal.msgBoxOk(validator.message);
        return;
      }

      if (this.isDataEquals(changes)) {
        const shouldCreate = await this.$bvModal.msgBoxConfirm('Are you sure you want to create a new revision request without any changes?', {
          title: 'Revision request has no changes',
          okTitle: 'Yes',
        });

        if (!shouldCreate) return;
      }

      const revision = this.makeRevisionData(
        this.entityId,
        this.entityType,
        newData,
        this.selectedRevisionRequest,
      );

      this.$emit('start-saving');

      try {
        const revisionSaved = await this.saveRevision(revision);
        Vue.prototype.$noty.success(`${this.translations.success.save}, id: ${revisionSaved?.id}`);
      } catch (error) {
        Vue.prototype.$noty.error(error.response?.data?.message || this.translations.errors.save, error);
      } finally {
        this.$emit('finish-saving');
        this.resetModal();
      }
    },
    async publishRevision(revision) {
      this.$emit('start-saving');
      // we want ignore live data erros
      const opts = { ignore_errors: true, is_publishing: true };
      const currentLiveData = this.mapToRevisionRequest ? this.mapToRevisionRequest(this.liveData, opts) : this.liveData;

      try {
        await service.publishRevisionRequest(revision.id, currentLiveData);
        this.viewLiveData = false;

        let successFeedback = this.translations.success.publish;
        if (this.skipToApproveAndPublish) {
          successFeedback = this.translations.success.approveAndPublish;
        }

        Vue.prototype.$noty.success(successFeedback);
        this.$emit('new-revision-request-published');
      } catch (e) {
        Vue.prototype.$noty.error(e.response?.data?.message || this.translations.errors.publish, e);
      } finally {
        this.$emit('finish-saving');
        this.resetModal();
      }
    },
    selectedRevisionRequestChanged(rev) {
      this.selectedRevisionRequest = rev;
      let changes = null;
      if (this.selectedRevisionRequest?.id > 0) {
        changes = this.mapToEntity ? this.mapToEntity(rev.requested_changes) : rev.requested_changes;
      }
      this.$emit('selected-revision-request-changed', changes);
    },
    validateSave() {
      const response = { valid: true, message: null };

      // can't save if we are in live data mode
      if (this.viewLiveData) {
        response.valid = false;
        response.message = 'You are in live data mode. Switch to draft to save changes.';
        return response;
      }

      // can't save if the form is not valid
      if (!this.isFromValid) {
        response.valid = false;
        response.message = 'The form is not valid. Please check the fields.';
        return response;
      }

      // can't save if selectedRevisionRequest is not draft
      if (this.pendingRevisionsRequests?.length > 0 && this.selectedRevisionRequest?.status !== REVISION_STATUS.draft) {
        response.valid = false;
        response.message = 'You can\'t save an revision request that is not in draft status.  '
                           + 'Please use back to draft option or reject / approve this revision request.';
        return response;
      }

      return response;
    },
  },
  computed: {
    alertColor() {
      if (this.viewLiveData) {
        return 'success';
      }
      return this.pendingRevisionsRequests.length > 0 ? 'warning' : 'success';
    },
    clonedCurrentData() {
      return R.clone(this.currentData);
    },
  },
};
</script>
